.showIcon {
  padding: 0 20px;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 18px;
}
.iconCard {
  position: relative;
  background: #ffffff;
  width: 120px;
  height: 80px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.iconCard.active {
  border: 1px solid #0078ff;
}
.iconCard p {
  font-size: 13px;
  margin-top: 4px;
}
.iconCard i {
  position: absolute;
  right: -4px;
  top: -8px;
  color: #d20000;
  cursor: pointer;
}
