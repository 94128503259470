@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
body {
  background: #fbfbfb;
  font-family: "DM Sans", sans-serif;
  overflow-x: hidden;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
textarea {
  width: 100%;
  outline: none;
  border: solid 1px #e3e8ed;
}
.Login_form {
  width: 50%;
  padding: 40px;
}
.flex-container {
  justify-content: center;
  display: flex;
}
.panel-title {
  font-size: 30px;
}
.sideNav {
  width: 68px !important;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #ffffff;
  height: 100%;
  text-align: center;
  padding-top: 17px;
  padding-right: 3px;
  z-index: 50;
  color: #242934;
  cursor: pointer;
  font-size: 28px;
  box-shadow: 0 0 7px 0 rgb(89 102 122 / 5%);
}
.offcanvas-sidenav.offcanvas .dashboard-item.menubox .fa-angle-down {
  margin-right: 0;
}
.sideNav-icon {
  width: 40%;
}
.menuboxitem {
  cursor: pointer;
}
.font34 {
  font-size: 34px;
}
.dashboard-item {
  color: white;
  font-size: 15px;
  padding-left: 26px;
  border: 0px;
  box-shadow: 0px 0px 10px black;
  width: 85%;
  margin-left: 9%;
  border-radius: 100px;
  margin-top: 13px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.menuboxitem-content {
  padding: 8px;
  border-radius: 8px;
}
.gsub-menu {
  background: #0b0a39;
}
.offcanvas-sidenav.offcanvas .menuboxitem-content .dashboard-item {
  background: transparent !important;
  margin-bottom: 2px;
  color: rgb(255 255 255 / 60%);
}
.offcanvas-sidenav.offcanvas .menuboxitem-content a:last-child .dashboard-item {
  margin: 0;
}
.offcanvas-sidenav.offcanvas .menuboxitem-content a {
  text-decoration: none;
}
.menuboxitem-content .dashboard-item .fa {
  font-size: 10px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
.offcanvas {
  background: linear-gradient(-45deg, #ffffff, #ffffff, #ffffff, #ffffff);
  background-size: 300% 400%;
  animation: gradient 10s ease infinite;
  color: rgb(0, 0, 0) !important;
}
.offcanvas-start {
  width: 250px !important;
}
.link {
  text-decoration: none;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.dashboard-item {
  background: #c41e2d;
  background: -moz-linear-gradient(left, #c41e2d 0, #f15b2f 100%);
  background: -webkit-linear-gradient(left, #c41e2d, #f15b2f);
  background: linear-gradient(90deg, #c41e2d 0, #f15b2f);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c41e2d",endColorstr="#f15b2f",GradientType=1);
  border-color: #fe6119 !important;
  box-shadow: 0 4px 10px -5px rgb(239 89 47 / 50%);
  color: rgb(255, 255, 255);
}
.dashboard-item:hover {
  background: linear-gradient(276deg, rgba(255, 255, 255, 0.898), #ffffff) 0 0
    no-repeat padding-box !important;
  color: black;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.898);
}
.dashboard-item:hover .menu-icon {
  color: black;
}
.offcanvas-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.date {
  width: 100%;
  border-radius: 10px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  background: linear-gradient(-45deg, #e6f3f5, #e6f1f7, #e2e7eb, #d6d3ff);
  color: rgb(41, 37, 44);
}
.dropdow-supplier {
  width: 100%;
  border-radius: 10px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  background: linear-gradient(-45deg, #e6f3f5, #e6f1f7, #e2e7eb, #d6d3ff);
  color: rgb(41, 37, 44);
  height: 100%;
}
.search-button {
  text-align: right;
  padding-top: 10px;
}
.Api-head {
  color: white;
  margin-top: 50px;
  margin-bottom: 30px;
}
.back-login {
  background-image: url("../src/Image/side1.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding-top: 9%;
}
.main-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 30px 0;
  background-image: url("../src/Image/login_bg.jpg");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  background-color: #fcfdff;
  position: relative;
}
.main-login::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgb(255 255 255 / 10%);
}
.autoUser-data {
  padding: 10px;
}
.autoUser-data table {
  color: white;
  text-align: center;
}
.head {
  font-weight: 800;
  font-size: 17px !important;
  color: black !important;
}
.flightDetailData div {
  font-size: 16px;
  margin-bottom: 4px;
  color: gray;
  text-align: center;
}
.flightDetail table {
  color: black;
}
.autoUser-data th {
  padding: 10px;
  background-color: transparent;
}
.autoUser-data td {
  padding: 10px;
  background-color: transparent;
}
.Search-autoUser {
  width: 30%;
  background-color: #ffffff;
  border-right: 0px;
  border-color: #fd8c00;
  padding: 5px 10px;
  box-shadow: 0px 0px 10px #524d46;
  border-radius: 30px;
  color: rgb(255, 255, 255);
  padding-left: 20px;
  padding-top: 7px;
}
/* form{ justify-content: center; display: flex; align-items: center; }
 */
.login-form {
  background: #fff;
  padding: 40px 30px;
  border: 0px solid #212121;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 6%);
  width: 95%;
  max-width: 450px;
  font-weight: 500;
  margin-top: 80px;
  position: relative;
  z-index: 4;
}
.forgot a {
  margin-left: 10px;
  color: #414141;
  font-weight: 600;
}
.icon-oflogo {
  background: #fff;
  display: block;
  width: 160px;
  height: 160px;
  line-height: 160px;
  border-radius: 50%;
  margin: -120px auto 15px;
  box-shadow: 0px -6px 8px -4px rgb(0 0 0 / 4%);
}
.login-form .form-control {
  background-color: #ffffff;
  border-color: #f0f0f0;
  padding: 6px 12px;
  height: 52px;
  letter-spacing: 0.6px;
  font-size: 16px;
  border-left: none;
}
.login-form .input-group-text {
  background: #ffffff;
  border-right: none;
  border-color: #f0f0f0;
  text-align: center;
  color: #9191a3;
  padding-right: 12px;
  padding-left: 16px;
}
.login-form .input-group-text .fa {
  font-size: 14px;
}
.btn-submit {
  width: 100%;
  background: #c41e2d;
  background: linear-gradient(90deg, #c41e2d 0, #f15b2f);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c41e2d",endColorstr="#f15b2f",GradientType=1);
  border-color: #fe6119 !important;
  box-shadow: 0 4px 10px -5px rgb(239 89 47 / 50%);
  border: 1px solid white;
  margin-top: 25px;
  padding: 6px;
  color: white !important;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  height: 50px;
  border: none;
}
.btn-search {
  background: #c41e2d;
  background: -moz-linear-gradient(left, #c41e2d 0, #f15b2f 100%);
  background: -webkit-linear-gradient(left, #c41e2d, #f15b2f);
  background: linear-gradient(90deg, #c41e2d 0, #f15b2f);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c41e2d",endColorstr="#f15b2f",GradientType=1);
  border-color: #fe6119 !important;
  box-shadow: 0 4px 10px -5px rgb(239 89 47 / 50%);
  width: 20%;
  border-radius: 20px;
  padding: 0px 10px;
  float: right;
  color: white;
}
.login-heading {
  text-align: center;
  font-weight: 900;
  margin-bottom: 40px;
  font-size: 25px;
}
.input-login {
  background: #ffffff;
  border: 1px solid #fd8c00;
  border-radius: 0px;
}
/* .input-login:active { background: transparent; border-bottom: 1px solid white; color: white; border-top: 0px; border-right: 0px; border-left: 0px; border-radius: 0px; }
 */
.pull-right {
  color: #7c7c7c;
}
.btn:focus,
.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.form-control:focus {
  box-shadow: none;
}
.forgot {
  font-size: 15px;
  text-align: center;
  margin-top: 40px;
  color: #a2a2a2;
  font-weight: 400;
}
.modal-content {
  box-shadow: 0px 0px 10px rgb(0 0 0);
  color: rgb(0, 0, 0);
}
.modal-body {
  background-color: transparent;
}
.tick {
  width: 100% !important;
}
.filter {
  display: flex;
  justify-content: center;
  margin-top: 8%;
  margin-bottom: 3%;
}
.autouser-div {
  padding-left: 10%;
  padding-right: 6%;
}
.flightdetailModal .modal-dialog {
  max-width: 1554px;
}
.flightdetailModal .modal-content {
  width: 104.7% !important;
}
.autoUser {
  background-color: #ffffff;
  border: none;
  margin-left: 10px;
  color: rgb(0, 0, 0);
}
.autoUser:focus {
  outline: none;
}
/* input:focus { border: none; }
 */
.card {
  background: transparent;
  box-shadow: 0px 0px 10px rgb(0 0 0);
  color: white;
  padding: 10px;
}
.img-flight {
  align-items: right;
}
.img-airline {
  width: 30%;
}
.dates {
  font-size: 12px;
  color: #ffffff;
  background: transparent;
  border: 2px solid white;
  padding: 4px;
}
.airport {
  padding: 10px;
  justify-content: center;
  display: flex;
}
.Middle {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 0px;
  margin-bottom: 20px;
}
.Middle2 {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 30px;
  margin-bottom: 0px;
}
.plane {
  padding: 0px;
  text-align: center;
}
/* .fa-solid { padding: 2px; color: rgb(145 21 223); }
 */
.search-icon {
  color: black;
}
.btn-book {
  background-color: #ffffff !important;
  border: 0px solid #fd8c00;
  border-radius: 2px;
  padding: 0px 10px;
  height: 100%;
}
* {
  padding: 0;
  margin: 0;
}
.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #24b907;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 120px;
  position: relative;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #24b907;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142; /* animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both; */
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
.menu-icon {
  color: #ffffff;
  width: 50;
  height: 50;
}
.logout {
  position: absolute;
  bottom: 6%;
  width: 90%;
  padding-bottom: 9px;
  border-radius: 100px;
  background: #c41e2d;
  background: -moz-linear-gradient(left, #c41e2d 0, #f15b2f 100%);
  background: -webkit-linear-gradient(left, #c41e2d, #f15b2f);
  background: linear-gradient(90deg, #c41e2d 0, #f15b2f);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c41e2d",endColorstr="#f15b2f",GradientType=1);
  border-color: #fe6119 !important;
  box-shadow: 0 4px 10px -5px rgb(239 89 47 / 50%);
  left: 6%;
  font-size: 15px;
  box-shadow: 0px 0px 10px #1e1e1ede;
  padding-top: 10px;
  color: white;
  text-align: center;
  justify-content: center;
}
/* #google-gh { background-image: linear-gradient( -120deg, #4285f4, #34a853, #fbbc05, #ea4335 ); -webkit-background-clip: text; background-clip: text; color: transparent; font-size: 33px; margin-left: 15px; }
 */
.google-text {
  font-size: 33px;
  margin-left: 18px;
}
.wrapper-space {
  float: left;
}
.sideNav-menu {
  color: #fd6500;
}
.google-table {
  border: 1px solid #d5d5d5;
}
.google-table tr th {
  white-space: nowrap;
  border-right: 1px solid #000;
}
.offcanvas-header {
  padding: 2rem 2rem 8px 2rem !important;
}
.checkmark__check {
  stroke: #24b907;
}
.check {
  padding: 17px;
  width: 50%;
}
.booking {
  font-size: 12px;
  margin-bottom: 2px;
  color: #b068de;
}
.user-header {
  transition: all 180ms;
  -webkit-transition: all 180ms;
  -o-transition: all 180ms;
  -moz-transition: all 180ms;
  -ms-transition: all 180ms;
  position: fixed;
  padding: 19px 20px;
  padding-left: 261px;
  width: 100%;
  z-index: 5;
  background: #fff;
  color: #000;
  box-shadow: 0 4px 8px rgb(18 38 63 / 3%);
}
.user-header .dropdown {
  cursor: pointer;
}
.user-header ul.dropdown-menu {
  min-width: 13rem;
  padding: 0 4px;
  box-shadow: 14px -5px 44px rgb(96 97 112 / 20%);
  border-color: #ffffff;
  margin-top: 59px !important;
  border-radius: 6px;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 160ms;
  animation-duration: 160ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.user-header ul.dropdown-menu::before {
  content: "";
  border-bottom: 10px solid #ffffff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: -10px;
  right: 9px;
}
.user-header ul.dropdown-menu .dropdown-item {
  padding: 0.6rem 1rem;
  font-size: 14px;
  color: #324253;
  border-radius: 6px;
  font-weight: 500;
}
.user-header ul.dropdown-menu .dropdown-item:hover {
  background-color: #f9f9f9;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.user-header li {
  border-bottom: 1px solid #f1f1f1;
  padding: 4px 0;
}
.user-header li:last-child {
  border-bottom: none;
}
.user-empty {
  min-height: 125px;
}
.user-button {
  width: 42px;
  padding: 2px;
  border: 1px solid rgb(226 226 226 / 10%);
  border-radius: 50%;
}
/* .btn-primary { color: transparent; background-color: transparent; border-color: transparent; }
 .btn-primary:hover { color: transparent; background-color: transparent; border-color: transparent; }
 .btn-primary:focus { color: transparent; background-color: transparent; border-color: transparent; box-shadow: none; }
 .btn-primary:active { color: transparent; background-color: transparent; border-color: transparent; box-shadow: none; }
 .show > .btn-primary.dropdown-toggle { color: transparent; background-color: transparent; border-color: transparent; box-shadow: none; }
 */
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.loader {
  width: 20%;
  height: 20%;
}
.notfound {
  justify-content: center;
  display: flex;
}
.loader-div {
  width: 100%;
  padding: 40px 0;
  top: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
}
/* // Loading animation */
.loader {
  border: 8px solid #e6e6e6;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 70px;
  height: 70px;
  animation: spin 600ms linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.successloged .modal-dialog {
  max-width: 450px;
}
.nofound {
  border-radius: 30px;
}
.ModalFull {
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  display: flex !important;
}
.ModalFulllast {
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
}
.ModalFulllast .modal-dialog {
  max-width: 1554px;
}
.ModalFulllast .modal-content {
  width: 104.7% !important;
}
.ModalFulllast th {
  padding: 2px 50px;
  border-bottom: 1px solid;
}
.ModalFulllast td {
  padding: 2px 50px;
}
.tick-icon {
  justify-content: center;
  display: flex;
}
.pnr-status {
  font-size: 17px;
  color: white;
  padding: 18px;
}
.booking-pnr {
  font-size: 15px;
}
.modal-dialog {
  max-width: 80rem;
}
.pnr-status-icon {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 15px;
}
.dropdown-sidenav {
  color: white;
  font-size: 15px;
  border: 0px;
  box-shadow: 0px 0px 10px black;
  width: 85%;
  margin-left: 9%;
  border-radius: 100px;
  margin-top: 13px;
}
.show-book {
  position: relative !important;
  background: #dc3545 !important;
}
.list-Booking {
  /* margin-top: 100px; */ /* height: 90%; */ /* overflow-x: scroll; */
}
.booktable {
  color: white;
  width: 100%;
  border: 0px solid white;
  text-align: center;
}
.data-scrool {
  display: block;
  overflow: auto;
  height: 80vh;
  padding: 0px;
}
.data-scrool3 {
  display: block;
  overflow: auto;
  height: 80vh;
  padding: 0px;
}
.data-scrool3 th {
  position: sticky;
  top: 0%;
}
.PaymentLayout1 {
  padding-left: 82px;
  padding-right: 16px;
  padding-top: 20px;
  margin-top: 2%;
}
.booktable th {
  padding: 10px;
  font-size: 18px;
}
.booktable td {
  padding: 10px;
  font-size: 14px;
  color: rgb(142, 142, 142);
}
.btn-Book {
  background-image: linear-gradient(45deg, #e29300, #ff8605);
  color: white;
  padding: 10px;
  margin-top: 10px;
}
.loadergif {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
}
.flightDetail {
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  box-shadow: 0px 0px 10px rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  padding: 10px;
  margin-top: 20px;
}
.flightDetailTitle {
  background: #c41e2d;
  background: -moz-linear-gradient(left, #c41e2d 0, #f15b2f 100%);
  background: -webkit-linear-gradient(left, #c41e2d, #f15b2f);
  background: linear-gradient(90deg, #c41e2d 0, #f15b2f);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c41e2d",endColorstr="#f15b2f",GradientType=1);
  border-color: #fe6119 !important;
  box-shadow: 0 4px 10px -5px rgb(239 89 47 / 50%);
  box-shadow: 0px 0px 10px rgb(0 0 0);
  color: white;
  padding: 10px;
}
.flightDetailData {
  color: rgb(0, 0, 0);
  padding-top: 20px;
}
.flightDetailData1 {
  color: rgb(0, 0, 0);
  padding-top: 0px;
  padding-left: 30px;
}
.black-line {
  color: black;
}
.Pre-Search {
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
}
.Pre-Search table {
  box-shadow: 0px 0px 10px #fd8c00;
}
.Pre-Search th {
  padding: 20px 40px 20px 40px;
  background-color: #fd8c00 !important;
  color: black;
}
.Pre-Search td {
  /* box-shadow: 0px 0px 10px #fd8c00; */
  color: black;
  padding: 10px 40px 10px 40px;
}
.Okay {
  float: right;
  margin-top: 15px;
  background-color: #fd8c00 !important;
  border: 1px solid #fd8c00;
  color: black;
}
.payment-detail {
  margin-top: 40px;
  margin-bottom: 20px;
}
.btn-auto {
  background: #c41e2d;
  background: -moz-linear-gradient(left, #c41e2d 0, #f15b2f 100%);
  background: -webkit-linear-gradient(left, #c41e2d, #f15b2f);
  background: linear-gradient(90deg, #c41e2d 0, #f15b2f);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c41e2d",endColorstr="#f15b2f",GradientType=1);
  border-color: #fe6119 !important;
  box-shadow: 0 4px 10px -5px rgb(239 89 47 / 50%);
  color: white;
  border: 1px solid white;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
}
.btn-add {
  background: linear-gradient(276deg, rgba(0, 110, 255, 0.898), #01568f) 0 0
    no-repeat padding-box !important;
  color: white;
  border: 1px solid white;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  min-width: 122px;
  height: 52px;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 8px;
}
.btn-modify {
  background: linear-gradient(276deg, rgba(1, 130, 37, 0.898), #006a35) 0 0
    no-repeat padding-box !important;
  color: white;
  border: 1px solid white;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 93%;
  border-radius: 4px;
}
.btn-modify_s {
  background: #1daa63;
  color: white;
  border: 1px solid white;
  font-weight: 700;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 93%;
  border-radius: 4px;
}
.btn-modify_p {
  background: #f2602f;
  color: #000;
  font-weight: 700;
  border: 1px solid white;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 93%;
  border-radius: 4px;
}
.btn-delete {
  background: linear-gradient(276deg, rgba(255, 0, 0, 0.898), #e13535) 0 0
    no-repeat padding-box !important;
  color: white;
  border: 1px solid white;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  padding-top: 5px;
  width: 93%;
  padding-bottom: 5px;
  border-radius: 4px;
}
.icon-add {
  color: white !important;
}
.UserData table {
  width: 100%;
  box-shadow: 0px 0px 5px whitesmoke;
}
.UserData th {
  padding: 10px;
  font-size: 20px;
  color: white;
}
.UserData td {
  padding-left: 2rem;
  padding-top: 3px;
  padding-bottom: 3px;
  padding: 10px;
  font-size: 13px;
}
.booktable tr {
  border-bottom: 1px solid rgb(225 225 225);
}
.AddUser {
  float: right;
}
.addBtn {
  margin: 0%;
}
.AddUserContainer {
  padding-top: 2%;
  padding-right: 2%;
}
.addUserData {
  width: 100%;
  margin-top: 8px;
  padding: 8px;
}
.faremanagement .UserData th {
  color: black;
  background-color: #e3e3e3;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  padding: 14px 12px 13px 13px;
}
.google-body .UserData th {
  color: black !important;
  background-color: #e3e3e3;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  padding: 6px 12px 9px 13px;
}
.none {
  background: none;
  border: none;
}
.delete {
  color: red !important;
}
.faremanagement .UserData table {
  box-shadow: none;
}
.transaction {
  padding: 5px !important;
  margin-top: 8px !important;
}
.amount {
  padding: 5px !important;
  margin-top: 8px !important;
}
.BookingLayout {
  padding-left: 82px;
  padding-right: 16px;
  padding-top: 20px;
  padding-bottom: 30px;
}
.PaymentLayout {
  padding-left: 82px;
  padding-right: 16px;
  padding-top: 20px;
  margin-top: 5%;
  padding-bottom: 30px;
}
.TripType {
  float: right;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  color: white;
  background: #c41e2d;
  background: -moz-linear-gradient(left, #c41e2d 0, #f15b2f 100%);
  background: -webkit-linear-gradient(left, #c41e2d, #f15b2f);
  background: linear-gradient(90deg, #c41e2d 0, #f15b2f);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c41e2d",endColorstr="#f15b2f",GradientType=1);
  border-color: #fe6119 !important;
  box-shadow: 0 4px 10px -5px rgb(239 89 47 / 50%);
  border-radius: 3px;
  box-shadow: 0px 0px 5px white;
  font-weight: 800;
}
.fareDetailINFO {
}
.fareTitleINFO {
  background-color: rgb(221, 221, 221);
}
.fareTitleINFO2 {
  background-color: rgb(221, 221, 221);
  padding: 20px;
}
.updatedForm {
  display: flex;
}
.titleFare {
  padding: 10px;
  text-align: center;
}
.accordion-button {
  padding: 6px !important;
}
.fare-summary-Box {
  padding-bottom: 60px;
}
.btn-logout {
  background-color: #96d5ff;
  width: 100%;
}
.logout-link {
  text-decoration: none;
}
.blog_bxrowpagi li.page-item.dots.activedot {
  display: inline-block;
}
.blog_bxrowpagi li.page-item.dots {
  display: none;
}
.blog_bxrowpagi a {
  font-size: 13px;
  font-weight: 500;
  border-radius: 50px !important;
  margin: 0 7px 4px;
  line-height: 24px;
  width: 36px;
  height: 36px;
  padding: 5px;
  color: #565656;
  border-color: #f9f9f9;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-transition: none;
  -moz-transition: none;
  user-select: none;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}
.blog_bxrowpagi .page-item.disabled .page-link {
  border-color: #ebebeb;
}
.blog_bxrowpagi .page-item .page-link:focus,
.blog_bxrowpagi .page-item.active .page-link:focus {
  box-shadow: none;
  background: transparent;
}
.blog_bxrowpagi .page-link:hover {
  color: #868686;
  background: transparent;
}
.blog_bxrowpagi .page-item.active .page-link:focus,
.blog_bxrowpagi .page-item.active .page-link {
  border-color: #607d8b !important;
  color: #ffffff;
  font-weight: 700;
  background: #607d8b;
  box-shadow: 0 6px 6px -2px rgb(96 125 139 / 35%);
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.currency-check {
  font-size: large;
  justify-content: center;
  padding: 15px;
}
.multiSelectContainer {
  margin-top: 10px !important;
}
.searchWrapper {
  background-color: white;
  padding: 2px !important;
  display: flex;
}
.multiSelectContainer input {
  min-height: 32px !important;
}
.optionContainer li {
  background: #ffffff;
  color: #607d8b;
  font-weight: 500;
}
.charts {
  margin-top: 4%;
}
.nv.page-item.disabled {
  opacity: 0.2;
  cursor: no-drop;
  display: none;
}
.pagination-footer {
  justify-content: center;
  padding: 28px 0 17px !important;
  background-color: #ffffff;
  left: 0px;
}
.blog_bxrowpagi .nv a {
  font-size: 22px;
  font-weight: 300;
}
/* .blog_bxrowpagi { padding: 8px 12px !important; }
 */
.data-scrool2 {
  display: block;
  overflow: auto;
  height: 90vh;
  padding: 0px;
}
.ModalFull .modal-dialog {
  max-width: 850rem !important;
  align-items: center;
  color: #000;
}
.ModalShownew .modal-dialog {
  max-width: 400rem !important;
  color: #000;
  display: flex;
  justify-content: center;
}
.ModalShownew .modal-content {
  width: 80% !important;
  border-width: 1px;
  border-color: silver;
  color: #000;
  margin: 10px 10px 10px 10px;
  padding: 20px;
}
.customTableRow {
  margin: 10px;
  padding: 20px;
}
.customTableHead {
  border-color: #eee;
  border-width: 1px;
}
.column {
  width: 5rem;
}
.FooterButton {
  display: flex;
  justify-content: flex-end;
}
.buttonClass {
  margin: 1rem;
}
.form-control::-webkit-input-placeholder {
  color: #777;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border: 1px solid #e4e4e4;
  appearance: none;
  font-size: 15px;
  height: 46px;
  border-radius: 6px;
  background: #ffffff;
  transition: none;
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border: 1px solid #ced4da;
  appearance: none;
  font-size: small;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 5px 5px 14px #5f5f5f, -5px -5px 14px #ffffff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.statPending {
  margin: 12rem;
  background-color: #e9631bde;
  color: white !important;
  text-transform: lowercase;
  font-weight: bold;
}
.statSuccess {
  margin: 5px;
  background-color: #b5ffc1;
  text-transform: lowercase;
  color: #19d132;
  font-weight: bold;
}
.PayStatPending {
  margin: 12rem;
  background-color: #ff7c00db;
  color: white !important;
  text-transform: lowercase;
  font-weight: bold;
}
.PayStatSuccess {
  margin: 5px;
  background-color: #b9ffc5;
  text-transform: lowercase;
  color: #19d132;
  font-weight: bold;
}
.PayTextStatPending {
  text-transform: lowercase;
  color: #d12f19;
  font-weight: bold;
}
.PayTextStatSuccess {
  text-transform: lowercase;
  color: #19d132;
  font-weight: bold;
}
.BookTextStatPending {
  text-transform: lowercase;
  color: #d12f19;
  font-weight: bold;
}
.BookTextStatSuccess {
  text-transform: lowercase;
  color: #19d132;
  font-weight: bold;
}
.pd-addfare {
  padding: 20px 27px;
  float: left;
  text-align: left;
  width: 100%;
  align-items: flex-start;
  display: flex;
}
.fareNEw .modal-content {
  background: #fff !important;
  color: #000 !important;
}
.mg-0 {
  margin: 0 !important;
}
.pg-0 {
  padding: 0 !important;
}
.fareTable {
  width: 50rem;
  border-color: #eee;
  border-width: 1px;
  margin: 10px;
  border-radius: 10rem;
}
.oneWayFareTable {
  width: 800rem;
  margin: 10px;
  border-color: #eee;
  border-width: 1px;
}
.table-border-custom {
  border: 1px dashed #eee;
}
.ml-2 {
  margin-left: 15px;
}
.mr-2 {
  margin-right: 15px;
}
.bookingDetails .UserData th {
  padding: 10px;
  font-size: 14px;
  color: white;
  text-align: center;
  text-transform: capitalize;
  font-weight: normal;
  position: sticky;
  top: 0%;
}
.input-alert {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid rgb(255, 156, 156);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid grey;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.inputGroupDirection {
  display: flex;
}
.toggleSwitch {
  background-color: whitesmoke;
}
.metaDashboard .metaData table {
  box-shadow: none;
}
.metaDashboard .metaData th {
  padding: 10px;
  background: #dddddd !important;
  color: black;
  text-align: center;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 15px;
}
.metaDashboard .metaData td {
  padding: 10px;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  font-weight: normal;
  border-color: rgb(221, 221, 221);
  border-top: 2px solid #efeded;
}
.MetaModal .modal-Title {
  color: black;
}
.MetaModal .modal-body {
  color: black;
}
.btn-metaSubmit {
  display: flex;
  flex-direction: row-reverse;
}
.Booking-list-filter-wrapper {
  padding-left: 82px;
  padding-right: 16px;
  padding-top: 20px;
  border: #000;
  border-width: 1px;
}
.Booking-list-filter-wrapper2 {
  padding-top: 20px;
  border: #000;
  border-width: 1px;
}
.collapsible-custom {
  margin-top: 1%;
  text-align: start;
}
.filter-google {
  padding-left: 82px;
}
.google-booking {
  margin: 10px 0 59px;
}
.filter-css {
  padding: 31px 22px 6px;
  margin-bottom: 30px !important;
  font-size: 14px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 0 3px rgb(0 0 0 / 10%) inset;
}
.menuboxbars {
  cursor: pointer;
  padding: 5px;
}
.input-classicsite {
  position: relative;
  margin-top: 5px;
}
.filter-css .input-classicsite .form-label,
.filter-css .form-label,
.input-classicsite .form-label {
  color: #3e3e3e;
  font-size: 13px;
  position: absolute;
  top: -8px;
  font-weight: 600;
  padding: 0px 7px;
  background: #fff;
  left: 9px;
  border-radius: 5px;
  display: inline-block;
  letter-spacing: 0.6px;
  z-index: 4;
}
.filter-css .input-classicsite .input-group-text,
.filter-css .input-group-text {
  background: #fafafb;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
  border-radius: 8px;
  color: #798286;
  text-shadow: 1px 1px 1px #ffffff;
  border-color: #e3e8ed;
  border-width: 1px;
  font-size: 13px;
  box-shadow: 0 6px 8px -6px rgb(8 21 66 / 6%);
  letter-spacing: 0.6px;
}
.input-classicsite .form-select,
.input-classicsite .form-control,
.multiselect-cs,
.filter-css .input-classicsite .form-control,
.filter-css .form-control {
  border: solid 1px #e3e8ed;
  border-radius: 10px;
  font-size: 14px;
  border-width: 1px;
  font-weight: 500;
  background-color: #ffffff;
  box-shadow: 0 6px 8px -6px rgb(8 21 66 / 6%);
  letter-spacing: 0.6px;
  height: 57px;
  padding: 4px 12px 4px 15px;
}
.filter-css .input-classicsite .form-control:focus,
.filter-css .form-control:focus {
  border-color: #9c9daa;
}
.filter-css .input-classicsite .form-select,
.filter-css .form-select,
.input-classicsite .form-select {
  height: 57px;
  box-shadow: 0 6px 8px -6px rgb(8 21 66 / 6%);
  font-size: 14px;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.7rem center;
  border-color: #e3e8ed;
  background-size: 11px 12px;
  padding-right: 28px;
  border-width: 1px;
  font-weight: 400;
  letter-spacing: 0.6px;
}
.BookingLayout .col-12 {
  padding: 0px !important;
}
.multiselect-cs .searchWrapper {
  border: none;
  top: 10px;
  left: -2px;
}
.mw-120,
.btn-submit.btnver-2.mw-120 {
  min-width: 130px;
}
.modal-backdrop.show {
  opacity: 0.7;
}
.multiselect-cs .optionListContainer {
  top: 100%;
  margin-top: 6px;
  left: 0;
  right: 0;
  z-index: 10;
}
.reqpre pre {
  margin: 0;
  max-height: 500px;
  text-align: left;
  border-radius: 12px;
  padding: 18px;
}
.modalbooknow-classic .reqpre.modal-body {
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.multiselect-cs .multiSelectContainer ul {
  border-color: #e3e8ed;
  box-shadow: 0 7px 3px -6px rgb(8 21 66 / 3%);
}
.filter-design {
  margin: 0px 12px;
}
.payu-header {
  margin-left: 10rem;
  padding: 5rem;
}
.login-form.payu-dateinput {
  max-width: none;
  width: 100%;
  padding: 30px;
  border-radius: 12px;
  margin-top: 0px;
}
.login-form.payu-dateinput .form-control {
  border-radius: 6px;
  padding-left: 16px;
  padding-right: 16px;
}
.login-form.payu-dateinput .btn-submit {
  padding-left: 20px;
  padding-right: 20px;
  text-transform: none;
  margin: 0;
  border-radius: 6px;
}
.date-picker-custom-class {
  border-radius: 1rem;
  border-color: aliceblue;
  padding: 1rem;
}
.customButton {
  background: linear-gradient(#ff521f, #f09819);
  border-color: aliceblue !important;
}
.wrapper-space {
  width: 100%;
  margin: 0px;
}
.wrapper-space .transaction-data th {
  padding: 10px;
  font-size: 14px;
  background: #fd6500 !important;
  color: white;
  text-align: center;
  text-transform: capitalize;
  font-weight: normal;
}
.google-body {
  padding: 2px 0 52px 64px !important;
}
.supplier-box {
  box-shadow: 0px 0px 10px black;
}
h6 {
  font-style: italic;
  text-align: center;
}
.container-custom {
  padding: 100px 0px 40px 130px;
}
.wrapper-space .transaction-data table {
  box-shadow: none;
}
.wrapper-space .transaction-data td {
  padding-left: 2rem;
  padding-top: 3px;
  padding-bottom: 3px;
  padding: 10px;
  font-size: 15px;
  border: 0.5px solid rgb(187, 187, 187);
}
.ShowAllGateway th {
  padding: 10px;
  font-size: 14px;
  background: #fd6500 !important;
  color: white;
  text-align: center;
  text-transform: capitalize;
  font-weight: normal;
}
.ShowAllGateway td {
  padding-left: 2rem;
  padding-top: 3px;
  padding-bottom: 3px;
  padding: 10px;
  font-size: 15px;
  border: 0.5px solid rgb(187, 187, 187);
}
.ShowAllGateway {
  padding: 2%;
}
.dashboard-item {
  color: white;
  font-size: 15px;
  padding-left: 26px;
  border: 0px;
  box-shadow: 0px 0px 10px black;
  width: 85%;
  margin-left: 9%;
  border-radius: 100px;
  margin-top: 13px;
  padding-top: 7px;
  padding-bottom: 7px;
  background: #c41e2d;
  background: -moz-linear-gradient(left, #c41e2d 0, #f15b2f 100%);
  background: -webkit-linear-gradient(left, #c41e2d, #f15b2f);
  background: linear-gradient(90deg, #c41e2d 0, #f15b2f);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c41e2d",endColorstr="#f15b2f",GradientType=1);
  border-color: #fe6119 !important;
  box-shadow: 0 4px 10px -5px rgb(239 89 47 / 50%);
}
.dashboard-item3 {
  color: white;
  font-size: 10px;
  padding: 26px;
  border: 0px;
  box-shadow: 0px 0px 10px black;
  width: 85%;
  margin-left: 9%;
  border-radius: 100px;
  margin-top: 13px;
  padding-top: 7px;
  padding-bottom: 7px;
  background: linear-gradient(276deg, rgba(0, 128, 47, 0.898), #4fef41) 0 0
    no-repeat padding-box !important;
}
.Modal .modal-dialog {
  max-width: 100rem !important;
  align-items: center;
  color: #000;
}
.Modal td {
  font-size: 14px;
  color: black;
  text-align: center;
  text-transform: capitalize;
  font-weight: normal;
  border: 1px solid;
  border-color: rgb(221, 221, 221);
}
.Modal th {
  padding: 10px;
  font-size: 14px;
  background: #fd6500 !important;
  color: white;
  text-align: center;
  text-transform: capitalize;
  font-weight: normal;
  border: 1px solid;
}
.ModalEdit .modal-dialog {
  max-width: 100rem !important;
  align-items: center;
  color: #000;
}
.form-split {
  display: flex;
  justify-content: space-around;
}
.button_set {
  align-self: end;
}
.modalbooknow-classic .btn-submit.btnver-2,
.filter-css .btn-submit.btnver-2 {
  height: 57px;
  letter-spacing: 1.4px;
}
.filter-css .btn-submit.btnver-2 {
  width: 100%;
}
.filter-css .button_bg,
.filter-css .form-control.button_bg,
.filter-css .input-classicsite .form-control.button_bg,
.button_bg {
  background: #c41e2d;
  background: -moz-linear-gradient(left, #c41e2d 0, #f15b2f 100%);
  background: -webkit-linear-gradient(left, #c41e2d, #f15b2f);
  background: linear-gradient(90deg, #c41e2d 0, #f15b2f);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c41e2d",endColorstr="#f15b2f",GradientType=1);
  border-color: #fe6119 !important;
  box-shadow: 0 4px 10px -5px rgb(239 89 47 / 50%);
  color: white;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.4px;
}
/* table */
.table-condensed > thead.BI_tablehead > tr > th {
  padding: 20px 10px 20px 20px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f6f6f6;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border-top: none;
  border-bottom: 2px solid #fff;
}
.table-condensed > tbody.BI_tablebody > tr > td {
  padding: 15px 10px 15px 20px;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
  color: #4d4d4f;
}
.fixed_header {
  table-layout: fixed;
  border-collapse: collapse;
}
.fixed_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  max-height: 400px;
}
.fixed_header thead tr {
  display: block;
}
.fixed_header th,
.fixed_header td {
  padding: 5px;
  text-align: left;
  width: 200px;
}
/* table end */
.tbody-dd {
  height: 300px;
}
.row-ss {
  margin: 50px 0px 0px 78px;
}
.pr-0-adms {
  padding: 30px 0px 20px 250px;
  transition: all 180ms;
  -webkit-transition: all 180ms;
  -o-transition: all 180ms;
  -moz-transition: all 180ms;
  -ms-transition: all 180ms;
}
/* @media (max-width: 1199px) { .pr-0-adms { padding: 18px 0px 20px 12px; }
 .pr-0-adms .container-fluid.p-5 { padding-right: 16px !important; }
 }
 */
.black {
  color: black !important;
}
.error-class {
  justify-content: center;
  height: 100vh;
  display: flex;
  align-items: center;
}
.metaBox {
  border: 1px solid rgba(66, 66, 66, 0.1);
  overflow: hidden;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 8%);
  display: table;
  width: 100%;
}
.form-switch {
  display: flex;
  justify-content: center;
}
/* .Collapsible__trigger { border-radius: 14px; background: #e1e1e1; box-shadow: 5px 5px 7px #b9b9b9, -5px -5px 7px #ffffff; padding: 10px 30px; color: #242424; font-weight: 600; display: inline-block; }
 */
.blue-bold {
  color: #00a1ff !important;
  font-weight: 800;
  font-size: 14px !important;
}
.green-bold {
  color: #02c000 !important;
  font-weight: 800;
  font-size: 14px !important;
}
.flightDetail-Google,
.bookingDetail-Google {
  border-radius: 0.75rem;
  padding: 20px 12px 12px;
  box-shadow: 0 4px 11px -2px rgb(0 0 0 / 4%);
  border: 1px solid #eaecf3;
  background-color: #ffffff;
}
.bookingDetail-Google h3 {
  margin-top: 6px;
  font-size: 18px;
}
.table thead tr {
  background-color: #1980e3;
  border-radius: 10px;
  box-shadow: none;
  color: white;
}
.hr-line {
  margin: 16px 0;
  background: #a2a2a2;
  height: 2px;
}
.rowad-fl {
  margin: 0 -20px;
}
.modalbooknow-classic .hr-line.gfbline {
  height: 0;
  border-top: dashed 1px #8f8f8f;
  background: transparent;
  margin: 50px 0 !important;
}
td.dbgsub-light.pt-0.border-0 {
  padding: 1px;
}
.flightDetail-Google h3 {
  margin-top: 6px;
  font-size: 18px;
}
.flightDetail-Google h4 {
  background-color: #1980e3;
  padding: 6px;
  box-shadow: none;
  color: white;
  border-radius: 6px;
}
.head-remark .row {
  background-color: #1980e3;
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
  border-radius: 6px;
}
.head-remark {
  padding: 2px;
}
.addRemark {
  float: right;
}
.body-remark {
  padding-right: 4px;
}
.body-input-remark .col-11 {
  padding-left: 0px;
  padding-right: 10px;
}
.body-input-remark .col-1 {
  padding: 0px;
}
.submit-remark {
  width: 100%;
  padding: 10px;
}
.form-control-remark {
  width: 100%;
  padding: 10px;
  border: 1px solid white;
  box-shadow: 0px 0px 10px gray;
  border-radius: 6px;
}
.extra_space {
  color: transparent;
}
.table.table-lightthm thead.bg-light th {
  color: #65698d;
  background-color: #f6f7ff;
  border-color: #ebecf8;
  font-weight: 500;
  border-top: 0;
  vertical-align: middle;
}
.table.table-lightthm thead.bg-light {
  background-color: #f5f8fd !important;
}
.autouser-inputgroup .btn-submit.btnver-2 {
  margin: 0;
  height: 60px;
}
.table-responsive.lightthm-wrap .table {
  margin: 0;
  background-color: #fff;
}
.table.table-lightthm thead tr {
  color: #495057;
  border: none;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: none;
}
.table.table-lightthm td,
.table.table-lightthm th {
  white-space: nowrap;
  font-size: 14px;
  padding: 0.5rem 1.5rem;
  height: 68px;
  vertical-align: top;
  border-bottom: 2px solid #e9f1df;
  border-right: none;
  border-left: none;
  vertical-align: middle;
  letter-spacing: 0.4px;
}
.table.table-lightthm td:last-child,
.table.table-lightthm th:last-child {
  border-right: none;
}
.table.table-lightthm tr:last-child td {
  border-bottom: none;
}
.table.table-lightthm > :not(:first-child) {
  border: none;
}
.btn-dark-add {
  text-align: right;
}
.btn-dark-add .btn {
  min-width: 122px;
  height: 42px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
}
.h-100-view {
  min-height: 72vh;
  font-weight: 500;
  text-align: center;
  font-size: 18px;
}
h6.title-lightthm,
.title-lightthm {
  margin-bottom: 13px;
  font-weight: 600;
  margin-top: 5px;
  text-align: left;
  color: #464646;
}
h6.title-lightthm {
  color: #4f587d;
  letter-spacing: 0.4px;
}
.table-responsive.lightthm-wrap {
  height: 450px;
  border: 1px solid #eaeff8;
  resize: vertical;
}
.table-responsive.lightthm-wrap thead th {
  font-weight: 500;
  position: -o-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  border: none;
  background-color: #ffffff;
  color: #668c9e;
  box-shadow: 0 -13px 0px -12px #efecff inset;
  -o-box-shadow: 0 -13px 0px -12px #efecff inset;
  -ms-box-shadow: 0 -13px 0px -12px #efecff inset;
  -moz-box-shadow: 0 -13px 0px -12px #efecff inset;
  -webkit-box-shadow: 0 -13px 0px -12px #efecff inset;
  text-transform: capitalize;
}
.smtb {
  padding: 0px;
}
.form-switch {
  width: 40px;
}
.table.table-lightthm .input-classicsite {
  margin: 0;
}
.form-switch .form-check-input {
  margin: 0;
  height: 20px;
  width: 40px;
}
.text-right {
  text-align: right;
}
.overlay-ofloader {
  position: fixed;
  padding-left: 250px;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 70%);
  color: #000;
  z-index: 6;
  -o-backdrop-filter: blur(12px);
  -moz-backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  font-weight: 400;
  transition: all 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -webkit-transition: all 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -o-transition: all 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -moz-transition: all 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -ms-transition: all 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.main-login .overlay-ofloader {
  padding: 0;
  top: 0;
}
.loaderdataload {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 10px -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e95e31;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.loaderdataload:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e95e31;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
.loaderdataload:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e95e31;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.offcanvas-sidenav .offcanvas-header {
  padding: 1.5rem 1rem !important;
  justify-content: center;
  border-bottom: solid 1px #1b1b3f;
  position: relative;
  z-index: 1;
}
.offcanvas-backdrop {
  display: none;
}
.offcanvas-sidenav.offcanvas {
  border: none;
  max-width: 250px;
  width: 100% !important;
  transition: all 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -webkit-transition: all 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -o-transition: all 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -moz-transition: all 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -ms-transition: all 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
  box-shadow: 0 0 21px 0 rgb(89 102 122 / 10%);
  visibility: visible;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  background: #030226;
}
/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 16px;
}
::-webkit-scrollbar-track {
  background-color: #ffffff;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #d1d6db;
  border: solid 4px #ffffff;
  min-height: 80px;
}
.offcanvas-sidenav.offcanvas .fa,
.offcanvas-sidenav.offcanvas .fa-solid {
  width: 24px;
  transition: all 0.45s ease;
  -ms-transition: all 0.45s ease;
}
.offcanvas-sidenav.offcanvas .dashboard-item {
  box-shadow: none;
  border-radius: 10px;
  background: transparent !important;
  padding: 10px 10px;
  color: rgb(255 255 255 / 60%);
  letter-spacing: 0.6px;
  font-weight: 400;
  margin: 0;
  font-size: 15px;
  width: 100%;
  text-align: left;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 6px;
}
.offcanvas-sidenav.offcanvas .menu-icon {
  width: 22px;
  color: #8890a7;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.offcanvas-sidenav.offcanvas .offcanvas-header .btn-close {
  border-left: 1px solid #f1f1f1;
  border-radius: 0;
  min-width: 52px;
  margin-right: -16px;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: none;
}
.m-500 {
  min-height: 500px;
}
.main-login .overlay-ofloader {
  background: rgb(0 0 0 / 80%);
  color: #fff;
}
img {
  max-width: 100%;
}
.bgthem-clr {
  color: #e95419;
}
.table.table-lightthm td * {
  padding: 0;
}
.table.table-lightthm td .input-classicsite .form-control,
.table.table-lightthm td .input-classicsite select.form-select,
.table.table-lightthm td .input-classicsite select.form-control {
  padding: 4px 12px 4px 15px;
}
.table.table-lightthm td .input-classicsite select.form-select {
  padding-right: 26px;
}
.offcanvas-sidenav.offcanvas a.active .dashboard-item {
  text-shadow: 0 0 0.65px #ffffff, 0 0 0.65px #ffffff;
}
.table.table-lightthm td .badge {
  padding: 0.9em 1.3em;
  border-radius: 50px;
  font-size: 10px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.table.table-lightthm td .badge.bg-success {
  color: #ffffff;
  background-color: #34c38f !important;
}
.table.table-lightthm td .badge.bg-danger {
  color: #ffffff;
  background-color: #f46a6a !important;
}
.offcanvas-sidenav.offcanvas a.active .dashboard-item .menu-icon,
.offcanvas-sidenav.offcanvas .dashboard-item:hover .menu-icon {
  color: #d44b14;
  text-shadow: none;
}
.welcomebck {
  -webkit-box-shadow: 0px 6px 23px 0px rgb(62 73 84 / 8%);
  box-shadow: 0px 6px 23px 0px rgb(62 73 84 / 8%);
  border-radius: 20px;
  margin-bottom: 40px;
}
.offcanvas-sidenav.offcanvas a.active .dashboard-item,
.collapsemenu-nv:hover .dashboard-item,
.offcanvas-sidenav.offcanvas .dashboard-item:hover {
  background: rgb(255 255 255 / 10%) !important ;
  color: #ffffff;
}
.collapsemenu-nv:hover .menu-icon {
  color: #d44b14;
}
.collapsemenu-nv {
  cursor: pointer;
}
.badge.bg-warningpending {
  background: #ffd045;
  color: #856800;
}
.offcanvas-sidenav.offcanvas .offcanvas-body {
  padding: 16px 6px 10px !important;
}
/* Scrollbar Styling */
.offcanvas-sidenav.offcanvas .offcanvas-body::-webkit-scrollbar {
  width: 8px;
}
.offcanvas-sidenav.offcanvas .offcanvas-body::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.offcanvas-sidenav.offcanvas .offcanvas-body::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #444468;
  border: solid 2px #030226;
  min-height: 80px;
}
.fa-refresh.update-ico {
  color: #4caf50;
  font-size: 17px;
  cursor: pointer;
}
.autouser-inputgroup .input-classicsite .form-control {
  background-color: #fff;
  border-color: #ebf0ff;
  padding-left: 20px;
  padding-right: 20px;
}
.autouser-inputgroup .input-classicsite .form-control,
.autouser-inputgroup .btn-submit.btnver-2 {
  height: 60px;
  font-size: 16px;
}
.btn.autouser-btn {
  width: 120px;
}
.btn.autouser-btn .fs {
  font-size: 50%;
}
.autouser-inputgroup .form-label {
  font-weight: 700;
  font-size: 19px;
  margin-bottom: 6px;
  color: #4d5f93;
  background: transparent;
  padding: 0;
  position: static;
  top: 0;
}
.user-header .dropdown-item .fa {
  color: #879fab;
}
.input-classicsite select.form-select,
.input-classicsite select.form-control {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-size: 10px;
  padding-right: 26px;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
}
.apitrc-dashboarddata .pr-0-adms,
.api-trc-dashboarddata .pr-0-adms {
  padding: 0;
}
.autouser-inputgroup .btn.autouser-btn .fa-solid {
  font-size: 14px;
}
.table-responsive.lightthm-wrap select.input-classicsite.form-select {
  font-size: 14px;
  height: 36px;
  padding: 6px 15px;
  cursor: pointer;
  border-color: #d9e6f8;
}
.text-sm {
  font-size: 14px;
}
.text-xs {
  font-size: 12px;
}
.mr-1-cal {
  margin-right: 6px;
}
.btn-submit.btnver-2 {
  display: inline-block;
  width: auto;
  font-size: 14px;
  min-width: 126px;
  height: 44px;
  border-radius: 8px;
  font-weight: 600;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 0;
}
.table.table-lightthm td .btn-success {
  background-color: #198754;
  border-color: #198754;
  padding: 0.375rem 0.75rem;
}
/* .blog_bxrowpagi_empty { height: 96px; }
 */
.table.table-lightthm tbody tr:last-child td {
  border-bottom: none;
}
.table-responsive.lightthm-wrap thead th br {
  display: none;
}
/* remove resize & scroll-auto */
.table-responsive.lightthm-wrap.scroll-auto {
  height: auto;
  resize: none;
}
.btn-submit.btnver-2.cache-menu {
  margin: 0;
  font-size: 14px;
  text-transform: none;
  background: #fff;
  color: #65698d !important;
  box-shadow: none;
  border: solid 3px #d7dafb !important;
}
tr.bg-infodep {
  background: #dbdbdb;
  padding: 0 10px;
}
tr.bg-infodep td {
  padding-left: 10px;
  padding-right: 10px;
}
.form-control-plaintext.spaceeq {
  height: 48px;
  outline: none;
}
.table-striped.table-lightthm > tbody > tr:nth-of-type(odd) {
  background-color: #ffffff;
}
.input-classicsite .form-control[disabled] {
  cursor: no-drop;
  background-color: #f8f8f8;
  border-color: #dfdfdf;
}
.table.table-lightthm thead.bg-light th,
.table.table-lightthm thead.bg-light {
  background-color: #193d60 !important;
  color: #fff;
}
/* Collapse */
.collapse {
  overflow: hidden;
  transition: 0.4s max-height;
}
.collapse > div {
  overflow: auto;
}
.sample-content {
  background: #ccc;
  padding: 10px;
}
h1,
p {
  margin: 0;
  text-align: center;
}
button {
  padding: 10px;
  display: block;
  background: #333;
  color: white;
  width: 100%;
  border: 0;
  cursor: pointer;
}
tr.cursor-pointer,
tr.cursor-pointer td,
tr.cursor-pointer .form-control-plaintext.spaceeq,
.cursor-pointer {
  cursor: pointer;
}
.text-left {
  text-align: left;
}
hr.border-lighttin {
  background: #cdcdcd;
  height: 2px;
  margin-top: 0px !important;
  margin-bottom: 27px !important;
}
.lightthm-wrap th select.form-control {
  border-width: 1px;
  border-color: #efefef;
  padding-left: 14px;
  padding-right: 26px;
  font-size: 14px;
  cursor: pointer;
  color: #565657;
  height: 39px;
  border-radius: 10px;
  box-shadow: 0 6px 8px -6px rgb(0 0 0 / 52%);
  max-width: 170px;
  background-size: 10px;
  letter-spacing: 0.6px;
}
.nospac.mb-4 .container-fluid.px-3 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.fa-2x {
  font-size: 17px;
}
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-style: normal;
}
.mt-cachepricefeed {
  margin-top: -48px;
}
.btn-submit.success {
  background: #3bad71;
  box-shadow: 0 4px 10px -5px rgb(69 203 133 / 50%);
}
.btn-submit.success:hover {
  background: #359a65;
}
.btn-submit.danger {
  background: #f46a6a;
  box-shadow: 0 4px 10px -5px rgb(69 203 133 / 50%);
}
.btn-submit.danger:hover {
  background: #e14c4c;
}
.btn-submit.btnver-2 i.fa-solid {
  font-size: 85%;
  vertical-align: middle;
}
.btn-submit.lightgry {
  background: #879fab;
  box-shadow: 0 4px 10px -5px rgb(96 125 139 / 50%);
}
.btn-submit.lightgry:hover {
  background: #566f7c;
}
.font-500 {
  font-weight: 500 !important;
}
.btn-submit.light {
  background: #eef2ff;
  box-shadow: 0 2px 10px -5px rgb(86 92 112 / 20%);
  color: #565c70 !important;
  border: none !important;
}
.btn-submit.light:hover {
  background: #e4e8f5;
}
.shadow-0 {
  box-shadow: none !important;
}
.table.table-lightthm td .btn-soft,
.btn-soft,
.btn-soft-success,
.table.table-lightthm td .btn-soft-success,
.btn-soft-danger,
.table.table-lightthm td .btn-soft-danger {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  width: auto;
  padding: 8px 14px;
  display: inline-block;
  border: none;
  font-weight: 500;
  border-radius: 8px;
  box-shadow: 0 0 0px 1px rgb(0 0 0 / 2%);
}
.btn-soft .fa,
.btn-soft .solid,
.btn-soft-success .fa-solid,
.btn-soft-success .fa,
.btn-soft-danger .fa-solid,
.btn-soft-danger .fa {
  font-size: 13px;
}
.btn-soft-danger,
.table.table-lightthm td .btn-soft-danger {
  background-color: rgb(244 108 108 / 10%);
  color: #f46a6a;
}
.btn-soft-danger:hover,
.table.table-lightthm td .btn-soft-danger:hover {
  background: #f46a6a;
  color: #fff;
}
.btn-soft-success,
.table.table-lightthm td .btn-soft-success {
  background-color: rgba(80, 165, 241, 0.1);
  color: #50a5f1;
}
.btn-soft-success:hover,
.table.table-lightthm td .btn-soft-success:hover {
  background: #50a5f1;
  color: #fff;
}
.btn-soft-green,
.table.table-lightthm td .btn-soft-green {
  background-color: #ddfff3;
  color: #34c38f;
}
.btn-soft-green:hover,
.table.table-lightthm td .btn-soft-green:hover {
  background: #34c38f;
  color: #fff;
}
.modalbooknow-classic.modal .form-control-plaintext,
.table.table-lightthm td {
  color: #212529;
  font-weight: 400;
}
.userheader-tp.active ~ .offcanvas-sidenav.offcanvas {
  max-width: 70px;
  overflow: visible;
  position: absolute;
}
.userheader-tp.active
  ~ .offcanvas-sidenav.offcanvas
  .offcanvas-body::-webkit-scrollbar {
  width: 10px;
}
.userheader-tp.active
  ~ .offcanvas-sidenav.offcanvas
  .offcanvas-body::-webkit-scrollbar-thumb {
  border-width: 3px;
}
.userheader-tp.active ~ .offcanvas-sidenav.offcanvas img.notsidenvactive,
img.sidenvactive,
.userheader-tp.active ~ .offcanvas-sidenav.offcanvas .titlenv {
  display: none;
}
.userheader-tp.active ~ .offcanvas-sidenav.offcanvas img.sidenvactive,
.offcanvas-header img.notsidenvactive {
  display: inline-block;
}
.userheader-tp.active ~ .offcanvas-sidenav.offcanvas .dashboard-item.text-left {
  text-align: center !important;
}
.userheader-tp.active ~ .offcanvas-sidenav.offcanvas .dashboard-item {
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}
.userheader-tp.active ~ .offcanvas-sidenav.offcanvas .offcanvas-body {
  padding: 79px 3px 47px !important;
  overflow: visible;
}
.userheader-tp.active
  ~ .offcanvas-sidenav.offcanvas
  .collapsemenu-nv
  .menuboxitem-content {
  position: absolute;
  left: 100%;
  bottom: -4px;
  top: auto;
  width: 300px;
  margin-left: 7px;
  display: none;
  transform-origin: bottom left;
  -o-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  -moz-transform-origin: bottom left;
  -webkit-transform-origin: bottom left;
  animation-delay: 80ms;
  -o-animation-delay: 80ms;
  -ms-animation-delay: 80ms;
  -moz-animation-delay: 80ms;
  -webkit-animation-delay: 80ms;
}
.userheader-tp.active ~ .offcanvas-sidenav.offcanvas .collapsemenu-nv::before {
  content: "";
  position: absolute;
  left: -5px;
  top: 0px;
  width: 90px;
  height: 100%;
}
.userheader-tp.active
  ~ .offcanvas-sidenav.offcanvas
  .collapsemenu-nv:hover
  .menuboxitem-content {
  display: block;
  -webkit-animation-name: fadeInLeftSmNav;
  animation-name: fadeInLeftSmNav;
  -webkit-animation-duration: 250ms;
  animation-duration: 250ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  height: auto !important;
}
@-webkit-keyframes fadeInLeftSmNav {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20%, 0, 0) scale(0);
    transform: translate3d(-20%, 0, 0) scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeftSmNav {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20%, 0, 0) scale(0);
    transform: translate3d(-20%, 0, 0) scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.userheader-tp.active ~ .offcanvas-sidenav.offcanvas .offcanvas-header {
  position: fixed;
  top: 0;
  left: 0;
  background: #030226;
  z-index: 3;
  width: 70px;
}
.userheader-tp.active ~ div .pr-0-adms,
.userheader-tp.active ~ div .w-100.metaDashboard.pr-0-adms,
.userheader-tp.active ~ .wrapper-space .w-100.metaDashboard.pr-0-adms,
.userheader-tp.active ~ .w-100.metaDashboard.pr-0-adms {
  padding-left: 70px !important;
}
.userheader-tp.active
  ~ div
  .w-100.metaDashboard.pr-0-adms
  .w-100.metaDashboard.pr-0-adms,
.userheader-tp.active
  ~ .w-100.metaDashboard.pr-0-adms
  .w-100.metaDashboard.pr-0-adms {
  padding-left: 0px !important;
}
.userheader-tp.active .user-header {
  padding-left: 90px;
}
.userheader-tp.active
  ~ .offcanvas-sidenav.offcanvas
  .gsub-menu
  .dashboard-item {
  text-align: left;
}
.userheader-tp.active ~ div .overlay-ofloader,
.userheader-tp.active ~ .overlay-ofloader {
  padding-left: 70px;
}
.userheader-tp.active ~ div .overlay-ofloader .overlay-ofloader {
  padding-left: 0;
}
.offcanvas-sidenav.offcanvas::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0px;
  width: 250px;
  height: 100%;
  background: #030226;
  transition: all 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -webkit-transition: all 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -o-transition: all 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -moz-transition: all 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -ms-transition: all 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
}
.offcanvas-sidenav.offcanvas .offcanvas-body {
  position: relative;
  z-index: 1;
}
.form-check label,
.form-check {
  cursor: pointer;
}
.userheader-tp.active ~ .offcanvas-sidenav.offcanvas::before {
  width: 70px;
}
.bs-tooltip-end {
  margin-left: 13px;
}
.metadialog .modal-dialog {
  width: 550px;
}
.modalbooknow-classic .modal-content {
  box-shadow: 0px 10px 60px rgb(0 0 0 / 32%);
  border-radius: 15px;
  overflow: hidden;
}
.modalbooknow-classic .modal-title.h4 {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 20px;
  color: #000;
}
.modalbooknow-classic .modal-header .btn-close {
  background-color: #ffffff;
  background-size: 12px;
  opacity: 1;
  border: solid 1px #f1f1f1;
  width: 22px;
  height: 22px;
  border-radius: 8px;
  margin-right: 0;
}
.modalbooknow-classic .modal-header,
.modalbooknow-classic .modal-body {
  padding-left: 20px;
  padding-right: 20px;
}
.modalbooknow-classic .modal-body {
  /* padding-top: 1.5rem; */
}
.modalbooknow-classic .modal-header {
  border-color: #f1f1f1;
  background-color: rgb(243 242 241 / 20%);
  padding-top: 20px;
  padding-bottom: 20px;
}
.modalbooknow-classic .form-check-inline {
  margin-right: 2rem;
}
.modalbooknow-classic.modal {
  -ms-backdrop-filter: blur(6px);
  -o-backdrop-filter: blur(6px);
  -moz-backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.modalbooknow-classic .spbor {
  background: #f1f1f1;
  opacity: 1;
}
body .multiSelectContainer li:hover {
  background: #fafafa;
  color: #000;
  cursor: pointer;
}
body .multiSelectContainer input {
  margin: 0;
}
.mb-4.itinhead {
  color: #444394;
  margin-bottom: 1.1rem !important;
  font-size: 18px !important;
}
.itinhead::after {
  width: 42px;
  height: 2px;
  background: #e95419;
  content: "";
  display: inline-block;
  margin: 4px 8px;
  border-radius: 40px;
  vertical-align: middle;
}
.input-classicsite .form-control.h-44,
.modalbooknow-classic .btn-sm.h-44,
.modalbooknow-classic .btn-submit.btnver-2.h-44 {
  height: 46px;
}
.modalbooknow-classic .multiSelectContainer {
  margin: 0 !important;
}
.lightthm-wrap .table td.bg-success {
  background-color: #02c000 !important;
}
.mb-4 {
  margin-bottom: 2rem !important;
}
.logout-fxm {
  padding: 8px 8px 4px;
  z-index: 3;
  position: relative;
}
.userheader-tp.active ~ .offcanvas-sidenav.offcanvas .logout-fxm {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 70px;
  padding: 3px 3px 0;
  background: #030226;
}
.modalbooknow-classic .hr-line {
  margin: 18px 0;
  background: transparent;
  height: 0;
  border-top: solid 1px #cccccc;
}
.table.table-lightthm.tbthm-2 th,
.table.table-lightthm.tbthm-2 td {
  border-width: 1px;
}
.form-control.font-16 {
  font-size: 16px;
}
.table.table-lightthm.tbthm-2 tr th,
.table.table-lightthm.tbthm-2 tr td {
  box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-bottom: 1px solid #f5f7fd;
}
.gheadicon {
  width: 25px;
  margin-right: 6px;
  vertical-align: sub;
}
.flightDetail-Google table.table-lightthm th,
.bookingDetail-Google table.table-lightthm th,
.flightDetail-Google table.table-lightthm td,
.bookingDetail-Google table.table-lightthm td {
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}
.flightDetail-Google select.form-select,
.flightDetail-Google .form-control,
.bookingDetail-Google select.form-select,
.bookingDetail-Google .form-control {
  min-width: 80px;
}
.table.table-lightthm td .badge.bdgsite,
.badge.bdgsite {
  border-radius: 50px;
  padding: 5px 14px;
  background: #1980e3;
  font-weight: 500;
  letter-spacing: 0.4px;
  font-size: 12px;
  line-height: normal;
  text-transform: uppercase;
  box-shadow: 0 2px 8px rgb(25 128 227 / 20%);
}
.table.table-lightthm.tbthm-2 tbody tr:last-child td {
  border-bottom: 1px solid #f5f7fd;
}
.modalbooknow-classic .table-responsive.lightthm-wrap.scroll-auto {
  border-radius: 14px;
}
.reactjson-wc {
  border-radius: 8px;
  background-color: rgb(30, 30, 30);
}
.table.table-lightthm td .reactjson-wc .react-json-view,
.reactjson-wc .react-json-view {
  margin: 0;
  text-align: left;
  border-radius: 8px;
  padding: 12px;
}
th.gtway-w {
  min-width: 250px;
  width: 250px;
}
.modalbooknow-classic.uploadby .modal-dialog {
  max-width: 540px;
}
.modalbooknow-classic.bdsList .modal-dialog {
  max-width: 740px;
}
.modalbooknow-classic.uploadby #formFile {
  height: 36px;
}
.dtbag {
  background: #f8f9fa;
}
.modalbooknow-classic .gfarebody .btn-submit.btnver-2,
.gfarebody .input-classicsite .form-select,
.gfarebody .input-classicsite .form-control {
  height: 50px;
}
.table.table-lightthm td table td,
.table.table-lightthm td table th {
  padding: 0.5rem 1.5rem;
}
.table.table-lightthm td.bgsub-light table td,
.table.table-lightthm td.bgsub-light table th {
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}
.table.table-lightthm td.bgsub-light {
  padding: 0;
}
td.bgsub-light .table-responsive.lightthm-wrap tr:hover,
.table.table-lightthm td.bgsub-light .table.table-lightthm,
td.bgsub-light .table-responsive.lightthm-wrap thead th,
td.bgsub-light .table-responsive.lightthm-wrap tbody td {
  background: #091b33;
  color: #fff;
  border-color: #394251 !important;
}
td.bgsub-light .form-control-plaintext {
  color: #fff;
}
td.bgsub-light .table-responsive.lightthm-wrap thead th {
  box-shadow: 0 -13px 0px -12px #394251 inset;
  -o-box-shadow: 0 -13px 0px -12px #394251 inset;
  -ms-box-shadow: 0 -13px 0px -12px #394251 inset;
  -moz-box-shadow: 0 -13px 0px -12px #394251 inset;
  -webkit-box-shadow: 0 -13px 0px -12px #394251 inset;
}
td.bgsub-light .table-responsive.lightthm-wrap {
  border-radius: 0;
  box-shadow: none;
  border: none;
}
td.bgsub-light .table-responsive.lightthm-wrap tbody td {
  border-color: #e0e3ea;
  border-width: 1px;
}
td.bgsub-light .table-responsive.lightthm-wrap tbody td.brout-itn {
  letter-spacing: 0.6px;
  border-right: 1px solid #edecfd;
  font-weight: 600;
  background: rgb(255 255 255 / 3%);
  color: #ffffff;
  border-color: #394251 !important;
  text-align: center;
}
.hidenull-td td:empty,
.hidenull-td th:empty {
  display: none;
}
/* .animtop { -webkit-animation-name: zoomIn; animation-name: zoomIn; -webkit-animation-duration: 450ms; animation-duration: 450ms; -webkit-animation-fill-mode: both; animation-fill-mode: both; transform-origin: top left; -webkit-transform-origin: top left; }
 @-webkit-keyframes zoomIn { 0% { opacity: 0; }
 50% { opacity: 1; }
 }
 @keyframes zoomIn { 0% { opacity: 0; }
 50% { opacity: 1; }
 }
  */
.table-lightthm.tbthm-2 tbody tr:nth-of-type(odd) > td,
.table-lightthm.tbthm-2 tbody tr:nth-of-type(odd) > th,
.table-lightthm.tbthm-2 thead tr:nth-of-type(odd) > td,
.table-lightthm.tbthm-2 thead tr:nth-of-type(odd) > th,
.table-lightthm tbody tr:nth-of-type(odd) > td,
.table-lightthm tbody tr:nth-of-type(odd) > th {
  background: #e9f1df;
  border-color: #e9f1df;
}
.bookingDetail-Google.gfaremodal thead th,
.gfaremodal.flightDetail-Google thead th {
  background: #23395d;
  color: #fff;
  border-color: #3c567f;
  box-shadow: 0 -13px 0px -12px #3c567f inset;
  -o-box-shadow: 0 -13px 0px -12px #3c567f inset;
  -ms-box-shadow: 0 -13px 0px -12px #3c567f inset;
  -moz-box-shadow: 0 -13px 0px -12px #3c567f inset;
  -webkit-box-shadow: 0 -13px 0px -12px #3c567f inset;
}
.leftspc-less .pr-0-adms {
  padding: 0;
}
.leftspc-less .metaBox {
  display: block;
}
#root {
  overflow-x: hidden;
}
.table-lightthm.tbthm-2 thead tr:nth-of-type(odd) > th {
  color: #728955;
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  cursor: not-allowed;
  filter: gray;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  opacity: 0.5;
}
.maxw-airlbx {
  max-width: 350px;
}
.maxw-airlbx span.badge {
  margin: 5px;
}
select.form-select.noof-result.form-control {
  padding-bottom: 0;
  margin: 0;
  height: 40px;
  min-width: 92px;
  border-radius: 9px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.input_time {
  width: 50px;
}
.multiselect-container span {
  padding: 0 11px 0 12px;
  border-radius: 60px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-content: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  align-items: center;
  min-height: 28px;
  margin-bottom: 7px;
  background: #3446ab;
  color: #ffffff;
  font-weight: 500;
}
.modalbooknow-classic .multiSelectContainer {
  height: auto;
  min-height: 57px;
  padding-bottom: 16px;
}
.multiselect-cs .searchWrapper {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.ovflw-visible .modal-content {
  overflow: visible;
}

@media (min-width: 1700px) {
  .reqpre pre {
    max-height: 600px;
  }
}
@media (max-width: 1499px) {
  .modalbooknow-classic.modal .modal-xl.modal-dialog-centered {
    max-width: 98%;
  }
}
@media (max-width: 767px) {
  .offcanvas-sidenav.offcanvas .dashboard-item {
    padding: 10px 8px;
  }
  .login-form {
    padding: 40px 15px;
  }
}

.edit_table input {
  background: transparent;
  border: none;
  color: #212529;
  width: 100%;
}
.edit_table input:focus {
  outline: none;
  background-color: #f5f5f5;
  padding: 2px;
  color: black;
  background-size: 100;
}
.custom_tabs {
  background-color: #0d6efd;
  border-radius: 10px;
  overflow-x: auto;
}
.custom_tabs .tab {
  font-weight: 500;
  border-radius: 10px;
  padding: 10px 0px;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s ease-in-out;
  font-size: 12px;
  min-width: 178px;
}
.custom_tabs .tab.active {
  background-color: white;
  color: black;
}
.avail_promo {
  background-color: #dfecff;
  padding: 5px 16px;
  font-size: 14px;
  border-radius: 20px;
  color: #2283ff;
  font-weight: 500;
}
.master_check {
  width: 65px;
}
.master_check.form-check.form-switch {
  padding-left: 0.5em;
}
.master_check .form-check-input {
  margin: 0;
  height: 30px;
}
.page_Not_found {
  width: 100%;
  height: calc(100vh - 80px);
  background: white;
  margin-top: -45px;
}
#all_rule {
  width: 60px !important;
}

.tile-content h4 {
  margin: 0;
  font-size: 1.1rem;
}
.tile-content p {
  margin: 5px 0 0;
  font-size: 0.9rem;
  color: #555;
}
.ins_tab_btn p {
  text-align: left;
}
.ins_tab_btn span {
  color: #0081ff;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
}
