.searchBar {
  width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.8rem;
  background: #ffffff;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
  color: #e95419;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}
/* .rwd-table {
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
} */

.rwd-table tbody tr th {
  border-top: none;
  background: #428bca;
  color: #fff;
}

.rwd-table tbody tr {
  border-radius: 10px;
}
